<template>
  <div class="dashboard workout-detail author-detail">
    <div class="workout-detail-block">
      <div class="workout-detail-top-wrapper">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
              <button type="button" class="heading-back" @click="$router.go(-1)">
                <chevronLeft/>
              </button>
              <p class="workout-detail-top-title d-none d-lg-block">{{ authorItem.title }}</p>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <button type="button" class="workout-detail-top-btn"  :class="[ authorItem.bookmarked ? 'active' : '']" v-on="authorItem.bookmarked == true ? { click: removeBookmark } : { click: addBookmark }">
                <bookmark/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="workout-detail-content-wrapper">
        <div class="container">
          <div class="workout-detail-content">
            <div class="row">
              <div class="col-12 col-lg-3">
                <div>
                  <div class="workout-detail-image text-center" v-if="!!authorItem.photo">
                    <img class="img-thumbnail" :src="authorItem.photo" alt="Author photo">
                    <div class="workout-detail-image-content">
                      <div class="container">
                        <div class="d-flex justify-content-start align-items-end">
                          <div class="d-lg-none">
                            <p class="workout-detail-subtitle">TREINO DE AUTOR</p>
                            <p class="workout-detail-title">{{ authorItem.title }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-9">
                <div class="workout-detail-text-wrapper" v-if="!!authorItem.description">
                  <div class="container">
                    <p class="workout-detail-text-title">Sobre o Autor</p>
                    <div class="workout-detail-text-description-wrapper">
                      <div class="workout-detail-text-description" v-html="authorItem.description"></div>
                      <div class="workout-detail-text-description-btn-wrapper d-flex justify-content-center align-items-center">
                        <button type="button" class="workout-detail-text-description-btn d-flex justify-content-center align-items-center" @click="toggleTextHeight">
                          <p>Ler Mais</p>
                          <span class="workout-detail-text-description-btn-icon">
                          <chevronDown/>
                        </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="workout-library-list">
            <div class="row workout-library-list-row" v-if="showLibrary">
              <div class="col-12 col-lg-4 col-xxl-3 workout-library-list-column" v-for="(item,index) in libraryItems" :key="index">
                <authorItem :authorWorkoutItem="item" class="item-mobile-layout-2"/>
              </div>
            </div>
            <p v-if="!showLibrary">Sem resultados</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authorItem from "@/components/author/author-item.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import bookmark from "@/components/svg/bookmark.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "author-detail",
  data() {
    return {
      lang: this.$root.siteLang,
      authorId: null,
      errors: [],
      authorItem: [],
      showLibrary: false,
      libraryItems: [],
      offset: 0,
      limit: 8,
      scroll: null
    }
  },
  metaInfo() {
    return {
      title: this.$root.translations.treino_autor,
    }
  },
  components: {
    authorItem,
    chevronLeft,
    bookmark,
    clock,
    level,
    fire,
    chevronDown,
  },
  methods: {
    handleScroll() {
      let element = document.getElementsByClassName('box-page-content')[0]
      if ((element.scrollHeight - element.offsetHeight) <= element.scrollTop) {
        this.offset = this.libraryItems.length
        this.limit = 4
        this.getWorkoutsList()
      }
    },
    getWorkoutsList() {
      let authKey = window.$cookies.get("authKey");

      this.$root.pageTitle = this.$root.translations.biblioteca;

      axios
          .get(process.env.VUE_APP_API_URL + 'authors/'+ this.authorId +'/products', {
            params: {
              lang: this.lang,
              offset: this.offset,
              limit: this.limit
            },
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.length === 0) {
              let element = document.getElementsByClassName('box-page-content')[0]
              element.removeEventListener('scroll', this.handleScroll);
            } else if (this.libraryItems.length > 0) {

              for (var elem in response.data) {
                this.libraryItems.push(response.data[elem])
              }
            } else {
              this.libraryItems = response.data;
            }
            this.showLibrary = true;
          })
          .catch((e) => {
            this.errors.push(e);
          }).finally((e) => {});
    },
    toggleTextHeight() {
      let element = document.getElementsByClassName("workout-detail-text-description-wrapper")[0];

      if ( !element.classList.contains("open") ) {
        element.classList.add("open");
      }
    },
    addBookmark(e) {
      e.preventDefault();

      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        related_id: this.authorItem.id,
        related_type: 1,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'bookmark', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.authorItem.bookmarked = true;

              let bookmark = {
                "id": this.authorItem.id,
                "title": this.authorItem.title,
                "photo": this.authorItem.photo,
                "workouts": this.authorItem.workouts,
                "slug": this.authorItem.slug
              };

              this.$root.authorBookmarks.push(bookmark);
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
    removeBookmark(e) {
      e.preventDefault();

      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        bookmark: this.authorItem.id,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'bookmark', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.authorItem.bookmarked = false;
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
  },

  mounted() {
    let element = document.getElementsByClassName('box-page-content')[0]
    element.addEventListener('scroll', this.handleScroll);

    this.getWorkoutsList()
  },

  created() {
    const areaSlugId = this.$route.params.id;
    const areaSlugParts = areaSlugId.split('-');
    const idAreaItem = areaSlugParts[areaSlugParts.length - 1];
    this.authorId = idAreaItem;

    axios
        .get(process.env.VUE_APP_API_URL + 'authors/'+ idAreaItem + '?lang=' + this.lang)
        .then((response) => {
          this.authorItem = response.data;
          this.authorItem.photo = process.env.VUE_APP_BASE_URL + this.authorItem.photo
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
  beforeDestroy() {
    let element = document.getElementsByClassName('box-page-content')[0]
    element.removeEventListener('scroll', this.handleScroll);
  }
}
</script>